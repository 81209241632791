import { graphql, Link } from "gatsby";
import Img from "gatsby-image";
import React from "react";
import { FaCheck, FaClock, FaCog, FaCubes, FaEye } from "react-icons/fa";

import BenefitBlock from "../components/benefitBlock";
import BenefitBlockWrapper from "../components/BenefitBlockWrapper";
import Columns from "../components/columns";
import Container from "../components/container";
import Hero from "../components/HeroBanner";
import Layout from "../components/layout";
import Lightbox from "../components/lightBox";
import SEO from "../components/seo";
import heroImage from "../images/system-dla-agencji-reklamowej.jpg";
import styles from "./index.module.css";
import { Helmet } from "react-helmet"

export const query = graphql`
  query {
    heroBg: file(relativePath: { eq: "system-dla-agencji-reklamowej.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    imgHero: file(
      relativePath: { eq: "system-dla-agencji-reklamowej-empireo-obraz-glowny.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1200, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    szanse: file(
      relativePath: { eq: "zarzadzanie-sprzedaza-system-dla-agencji.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    projekty: file(
      relativePath: {
        eq: "zarzadzanie-projektami-empireo-system-dla-agencji.jpg"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    dane: file(relativePath: { eq: "analiza-zlecen-empireo.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    klient: file(relativePath: { eq: "kartakontrahenta-empireo.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;

const IndexPage = ({ data }) => (
  <Layout>
    <SEO
      title="System dla agencji reklamowej i marketingowej"
      description="System dla agencji reklamowej ✅ System do zarządzania agencją marketingową ✅ Wsparcie zespołu sprzedaży i realizacji •  Zapytaj o bezpłatne demo!"
      keywords={["system dla agencji reklamowej"]}
    />
        <Helmet>
<meta name="facebook-domain-verification" content="yj5cqu7namu2bif24z9vubxai57x7n" />
</Helmet>


    <Hero
      heroImage={data.heroBg}
      height="100%"
      imageHeight="calc(100vh - 60px)"
    >
      <div
        style={{
          marginTop: 50,
        }}
      >
        <h1
          style={{
            fontSize: 48,
            marginBottom: 20,
            marginTop: 50,
          }}
        >
          Wyprzedź inne agencje reklamowe
        </h1>
        <h2
          style={{
            fontSize: 28,
             marginBottom: 20,
          }}
        >
          Przejmij kontrolę nad procesami sprzedaży i realizacji 
        </h2>
      </div>
      <div
        style={{
          width: "100%",
          maxWidth: "1000px",
        }}
      >
        <div>
          <Img
            style={{
              width: "100%",
              margin: "0 auto",
             maxWidth: "calc(100vh - 20px)",
            }}
            fluid={data.imgHero.childImageSharp.fluid}
            alt="System CRM dla agencji reklamowej"
          />
        </div>
      </div>
    </Hero>
    <Container>
      <h2
        style={{
          textAlign: "center",
          fontSize: 30,
          marginTop: 20,
        }}
      >
        Kompletne rozwiązanie dla Twojej firmy
      </h2>
      <div className="text-answers">
        <div className="text-block">
          <p>
            Empireo to system{" "}
            <strong>dla agencji reklamowych i marketingowych</strong>. Jest
            nowoczesnym narzędziem do zarządzania pracą{" "}
            <strong>zespołu sprzedażowego</strong> oraz{" "}
            <strong>działu realizacji</strong>. Jedno rozwiązanie - wiele
            możliwości dla Twojej firmy.
          </p>
        </div>
      </div>
    </Container>
    <Container>
      <Columns>
        <div className="text-answers">
          <div className="text-block">
            <h2
              style={{
                textAlign: "left",
                paddingLeft: "10px",
              }}
            >
              Sprzedawaj jak nigdy dotąd
            </h2>
            <p>
             Z Empireo poukładasz <strong>kluczowe procesy sprzedażowe</strong> Twojej firmy - od otrzymania 
             zapytania po działania posprzedażowe. Jedno, spójne rozwiązanie i jasne zadania dla pracowników. 
             Dzięki temu masz pewność, że <strong>nie stracisz ani jednej szansy na sprzedaż.</strong>
              <ul className={styles.featureList}>
                <li>
                  {" "}
                  <FaCheck /> Zarządzaj wieloma różnymi kampaniami sprzedaży w
                  jednym miejscu.
                </li>
                <li>
                  <FaCheck /> Zaprojektuj etapy i działania handlowe względem
                  szans sprzedaży.{" "}
                </li>
                <li>
                  <FaCheck /> Monitoruj wyniki sprzedażowe.
                </li>
                <li>
                  <FaCheck /> Analizuj powody utraty szans.
                </li>
                <li>
                  <FaCheck /> Wprowadzaj usprawniania i optymalizuj proces.
                </li>
              </ul>
            </p>
          </div>
          <div className="text-block">
            <Lightbox
              style={{
                maxWidth: 500,
                margin: "0 auto",
              }}
              images={[data.szanse]}
              alts={[
                "Zarządzanie szansami sprzedaży w systemie dla agencji reklamowej i marketingowej",
              ]}
            />
          </div>
        </div>
      </Columns>
    </Container>
    <Container>
      <Columns>
        <div className="text-answers">
          <div className="text-block">
            <Lightbox
              style={{
                maxWidth: 400,
                margin: "0 auto",
              }}
              images={[data.projekty]}
              alts={[
                "Zarządzanie zleceniami i projektami w systemie dla agencji",
              ]}
            />
          </div>
          <div className="text-block">
            <h2
              style={{
                textAlign: "left",
                paddingLeft: "10px",
              }}
            >
              Monitoruj na bieżąco postępy prowadzonych projektów
            </h2>
            <p>
              W agencji reklamowej{" "}
              <strong>realizujecie wiele zleceń jednocześnie</strong>.
              Nadzorowanie ich wszystkich stanowi ogromne wyzwanie. Empireo
              <strong>
                {" "}
                usprawni planowanie i&nbsp;monitorowanie wszystkich zleceń i
                projektów
              </strong>{" "}
              prowadzonych w&nbsp;Twojej firmie.
              <ul className={styles.featureList}>
                <li>
                  {" "}
                  <FaCheck /> Zarządzaj wieloma zleceniami i projektami.
                </li>
                <li>
                  <FaCheck /> Sprawnie przydzielaj nowe zlecenia.{" "}
                </li>
                <li>
                  <FaCheck /> Monitoruj status realizacji zleceń.
                </li>
                <li>
                  <FaCheck /> Twórz własne widoki kanban i optymalizuj przepływ
                  zleceń.
                </li>
              </ul>
            </p>
          </div>
        </div>
      </Columns>
    </Container>
    <Container>
      <Columns>
        <div className="text-answers">
          <div className="text-block">
            <h2
              style={{
                textAlign: "left",
                paddingLeft: "10px",
              }}
            >
              Podejmuj decyzje biznesowe na podstawie danych
            </h2>
            <p>
              Analiza działań sprzedażowych i marketingowych to klucz do sukcesu. Empireo oferuje zestawienie najważniejszych 
              danych z systemu i prezentuje Ci gotowe, <strong>zawsze aktualne raporty.</strong>
              <ul className={styles.featureList}>
                <li>
                  {" "}
                  <FaCheck /> Poznaj przyczyny utraty szans sprzedaży.
                </li>
                <li>
                  <FaCheck /> Sprawdź, czy w Twojej firmie występują wąskie
                  gardła.
                </li>
                <li>
                  <FaCheck /> Prognozuj sprzedaż w kolejnych okresach.
                </li>
                <li>
                  <FaCheck /> Monitoruj pracochłonność zleceń.
                </li>
              </ul>
            </p>
          </div>
          <div className="text-block">
            <Lightbox
              style={{
                maxWidth: 500,
                margin: "0 auto",
              }}
              images={[data.dane]}
              alts={[
                "Analizuj wyniki sprzedażowe w systemie do zarządzania agencją",
              ]}
            />
          </div>
        </div>
      </Columns>
    </Container>
    <Container>
      <Columns>
        <div className="text-answers">
          <div className="text-block">
            <Lightbox
              style={{
                maxWidth: 400,
                margin: "0 auto",
              }}
              images={[data.klient]}
              alts={["Baza kontrahentów."]}
            />
          </div>
          <div className="text-block">
            <h2
              style={{
                textAlign: "left",
                paddingLeft: "10px",
              }}
            >
              Zadbaj o ciągłość współpracy z klientami 
            </h2>
            <p>
              Relacje z kontrahentami buduje się od pierwszego kontaktu. Pokaż swoim klientom, że są dla Ciebie ważni. Przechowuj <strong>wszystkie informacje o&nbsp;waszej 
              współpracy w systemie</strong>. Podczas nieobecności pracownika, inna osoba z łatwością podejmie temat. 
              <ul className={styles.featureList}>
                <li>
                  {" "}
                  <FaCheck /> Twórz historię zdarzeń z kontrahentami.
                </li>
                <li>
                  <FaCheck /> Zbieraj wszystkie powiązane z kontrahentem dokumenty.
                </li>
                <li>
                  <FaCheck /> Dodaj własne atrybuty opisujące kontrahentów.
                </li>
                <li>
                  <FaCheck /> Kompletuj wszystkie informacje zebrane od kontrahenta.
                </li>
              </ul>
            </p>
          </div>
        </div>
      </Columns>
    </Container>
    <Container>
      <Columns>
        <div className="text-answers">
          <div className="text-block">
            <h2>Integracje z popularnymi systemami ERP</h2>
            <p>
              Jeżeli korzystasz z systemu handlowo-magazynowego, możesz go
              zintegrować z{" "}
              <strong>systemem do zarządzania agencją reklamową</strong>. W ten
              sposób w jednym miejscu znajdą się informacje o produktach,
              stanach magazynowych, cenach, fakturach, zleceniach i innych.
            </p>
            <p>
              Empireo integruje się z{" "}
              <strong>
                Subiekt GT, Subiekt Nexo, Sage Symfonia Handel, Sage Symfonia
                ERP, Comarch Optima, Comarch CDN XL, Asseco Wapro Wf-Mag,
                Navireo, Enova
              </strong>
              . Na liście nie ma Twojego programu handlowo-magazynowego? Napisz
              do nas i porozmawiajmy o możliwościach.
            </p>
          </div>
        </div>
      </Columns>
    </Container>
    <section
      className={styles.pricingSection}
      style={{
        backgroundColor: "rgba(0, 0, 0, 0)",
        color: "rgb(48, 48, 48)",
        padding: 40,
      }}
    >
      <div className="container container--text">
        <h2>Dlaczego możesz zaufać Empireo?</h2>
        <BenefitBlockWrapper>
          <BenefitBlock
            icon={<FaClock size="45px" />}
            title="Szybkie wdrożenie"
          >
            <p
              style={{
                textAlign: "center",
              }}
            >
              Już podczas testów działasz na swoim docelowym systemie.
            </p>
          </BenefitBlock>
          <BenefitBlock
            icon={<FaCubes size="45px" />}
            title="Szybka integracja"
          >
            <p>Nasze doświadczenie przekłada się na szybką integrację z ERP.</p>
          </BenefitBlock>
          <BenefitBlock icon={<FaCog size="45px" />} title="Duża elastyczność">
            <p>Pokażemy Ci, jak samodzielnie możesz dopasować system.</p>
          </BenefitBlock>
          <BenefitBlock icon={<FaEye size="45px" />} title="Przejrzystość">
            <p>
              Empireo nie wymaga czasochłonnego szkolenia, jest prosty w
              obsłudze.
            </p>
          </BenefitBlock>
        </BenefitBlockWrapper>
      </div>
    </section>
    <Container>
      <Columns>
        <div className="text-answers">
          <div className="text-block">
            <p>
              <h2
                style={{
                  textAlign: "center",
                  paddingLeft: "10px",
                }}
              >
                Sprawdź już dziś jak możesz odmienić pracę swojej agencji
              </h2>
              <h2
                style={{
                  textAlign: "center",
                  paddingLeft: "10px",
                  fontSize: 18,
                }}
              >
                30 dni za darmo. Bez zobowiązań. Bez karty kredytowej.
              </h2>
            </p>
            <p>
              {" "}
              <center>
                <Link className={styles.special} to="/demo/">
                  WYPRÓBUJ TERAZ
                </Link>
              </center>
            </p>
          </div>
        </div>
      </Columns>
    </Container>
  </Layout>
);

export default IndexPage;
